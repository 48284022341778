import { AnonymousGuard } from './shared/services/annonymous-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { PermissionsNotFoundComponent } from './shared/components/permissions-not-found/permissions-not-found.component';
import { AuthRedirectComponent } from './shared/components/auth-redirect/auth-redirect.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AnonymousGuard],
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
  },
  {
    path: 'pages',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),

  },
  {
    path: 'permissions-not-found',
    canActivate: [AuthGuardService],
    component: PermissionsNotFoundComponent,
  },
  {
    path: 'auth-redirect',
    canActivate: [AuthGuardService],
    component: AuthRedirectComponent,
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },
  { path: 'not-found', component: NotFoundComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
