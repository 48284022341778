import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, interval } from 'rxjs';
import { distinctUntilChanged, takeUntil, switchMap, tap, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { SessionStorageService } from './session-storage.service';
import { Router } from '@angular/router';
import { InactivityAlertComponent, InactivityAlertData } from '../modals/inactivity-alert/inactivity-alert.component';

@Injectable({
  providedIn: 'root'
})
export class InactivitySessionService {

  //private inactivityTimer: Subject<number> = new Subject();
  private inactivityTimer = new BehaviorSubject(0);
  private inactivityTime = 15 * 60; // 15 minutes
  private alertActive = false;
  private alertTimeout: any;

  constructor(private dialog: MatDialog, private auth: AuthService, private sessionStorage: SessionStorageService, public router: Router) { }

  // watchInactivity implementation
  public watchInactivity(): Observable<number> {
    return this.inactivityTimer.pipe(
      switchMap(() => interval(1000)), // Restart interval on every emission
      distinctUntilChanged()
    );
  }

  public resetTimer() {
    this.alertActive = false;
    this.inactivityTimer.next(1); // Restart inactivity counter
  }

  private showAlert() {
    if (!this.alertActive) {
      this.alertActive = true;

      // Alert logic using a custom timer (replace with your preferred method)
      let timer = 300; // Start timer at 30 seconds
      this.alertTimeout = setInterval(() => {
        timer--;
        if (timer === 0) {
          clearInterval(this.alertTimeout);
          this.alertActive = false;
          this.sessionStorage.clearSessionData();
          this.router.navigate(['/login']);
        }
      }, 1000); // Update timer every second

      const dialogData: InactivityAlertData = {
        timer: 300, // Pass timer value (seconds)
      };

      this.dialog.open(InactivityAlertComponent, { data: dialogData })
        .afterClosed().subscribe((result: any) => {
          if (result) { // User clicked "Stay Active"
            this.resetTimer();
            clearInterval(this.alertTimeout);
          } else {
            clearInterval(this.alertTimeout);
            this.sessionStorage.clearSessionData();
            this.router.navigate(['/login']);
          }
        });
    }
  }

  public handleInactivity() {
    this.watchInactivity()
      .pipe(
        // Map inactivity count to countdown timer for display
        map(count => {
          return this.inactivityTime - count;
        }),
        tap(count => console.log('Inactivity count (adjusted for timer):', count)) // Optional logging
      )
      .subscribe(count => {
        if (count <= 0 && this.auth.isAuthenticated()) {
          this.showAlert();
        } else if (!this.auth.isAuthenticated()) {
          this.resetTimer();
        }
      });
  }
}
