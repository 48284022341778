import { GenericModalComponent } from './../../shared/modals/generic-modal/generic-modal.component';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, from, Observable, throwError } from 'rxjs';
import { GenericModalService } from 'src/app/shared/services/generic-modal.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators'
import { EnterpriseTranscriptionService } from 'src/app/shared/services/enterprise-transcription.service';
import { AppCommonService } from 'src/app/shared/services/app-common.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService, private appCommonService: AppCommonService, private authorizationService: AuthorizationService,public router: Router,private sessionStorage: SessionStorageService) {}
  // Calculate the threshold for showing the alert 
  refresh_session_ageVal = localStorage.getItem('refresh_session_age')||'0';
  readonly refresh_session_age = parseInt(this.refresh_session_ageVal);
  /** 
   * `true` if the refresh token check is ongoing
   * @default false
  */
  isCheckOngoing = false
  /**
   * Stalls the incoming request with the 'Authorization' header until refresh token check completes and passes new token to the stalled apis and let them execute.
   * @default null
   */
  refreshTokenSubject = new BehaviorSubject<string|null>(null)
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // we only want to check token expiry IF the request we are sending out requires authorization. We'll confirm that
    // by checking for the header
    // checking expiry time
    // Get the current time in milliseconds
    const currentTime = Date.now();
    let expirationTime: any = localStorage.getItem('expiryTime');
    // Calculate the difference in milliseconds between current time and expiration time
  
    const timeDifference = currentTime - parseInt(expirationTime);

    console.log('$$timeDifference', timeDifference);
    if (timeDifference > this.refresh_session_age) {
      console.log('@@@@Token expired crossed loginThreshold****');
      this.sessionStorage.clearSessionData();
      this.router.navigate(['/login']);
    } else {
    if (request.headers.has('Authorization') && !this.isCheckOngoing) {
      this.isCheckOngoing = true
      this.refreshTokenSubject.next(null) // Make the BehaviorSubject value as null to stall the api's which are called during the refresh token check.
      // this is a request that requires auth,
      return from(this.refreshTokenIfExpired(request))
        .pipe(
          switchMap((newRequest) => (newRequest ? next.handle(newRequest) : EMPTY)), // EMPTY will cancel the request (in the case of token refresh failing)
          tap(_ => {
            this.isCheckOngoing = false
            this.refreshTokenSubject.next(localStorage.getItem('token'))
          } )
        );
    }else if(request.headers.has('Authorization')){
      if(this.isCheckOngoing){
        return this.refreshTokenSubject.pipe(
          filter((token): token is string => typeof token === 'string'), // if value is string then continue else stalls the api execution.
          take(1),
          switchMap((token) => next.handle(request.clone({ setHeaders:{Authorization: `Bearer ${token}`}}))) // switch to the stalled api with the latest token value.
        )
      }
    }
  }
    return next.handle(request);
  }

  /**
   * Check if token is expired and attempt to refresh it if necessary.
   * Will update the token attached to the passed request if the token refresh was necessary and successful.
   * If the token refresh fails, refreshToken() will sign people out.
   *
   * @param request - The request to modify with the new token.
   */
  async refreshTokenIfExpired(request: HttpRequest<unknown>) {
    // check if token has expired

    if (!this.appCommonService.isUserLoggedIn(true)) {
      // token has expired, so attempt to refresh
      try {
        await this.authorizationService.refreshToken();
        const token = localStorage.getItem('token');
        this.refreshTokenSubject.next(token) // emiting the new token value in the BehaviorSubject.
        const newRequest = request.clone({ setHeaders:{Authorization: `Bearer ${token}`} });
        return newRequest;
      } catch (e) {
        // this.toast.error('Session expired. Please login to continue.');
        return; // return nothing, this will then be used in the interceptor to return EMPTY and cancel the request
      }
    }
    return request;
  }
  }