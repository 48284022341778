import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { FilesFilters } from '../models/files-filters-model';
import { NewJobOptions } from '../models/new-job-options';
import { ApiService } from './api.service';
import { AppCommonService } from './app-common.service';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  config: any;
  private fileFilterListener = new BehaviorSubject<FilesFilters | null>(null);
  private billingClickEvent = new Subject<Event>();
  private addPaymentClickEvent = new Subject<Event>();
  public recentFileFilters: FilesFilters | null;
  private _totalRecords = new BehaviorSubject<number>(0);
  public totalRecords: number;

  constructor(private api: ApiService, private http: HttpClient, private commonService: AppCommonService) {
    this.config = AppConfig;
  }

  /* Files Filters related methods */
  get totalRecords$() {
    return this._totalRecords.asObservable();
  }

  setTotalRecords(value: number) {
    this.totalRecords = value;
    this._totalRecords.next(value);
  }

  get getFileFilters$() {
    return this.fileFilterListener.asObservable();
  }

  setFileFiltersValue(value: FilesFilters | null) {
    this.recentFileFilters = value;
    this.fileFilterListener.next(this.recentFileFilters);
  }
  /**
   * Observable of click event.
   */
  get getBillingClickEvent$() {
    return this.billingClickEvent.asObservable()
  }

  /**
   * Emit click event.
   */
  set billingClicked(value: Event) {
    this.billingClickEvent.next(value);
  }

  /**
   * Observable of click event.
   */
  get addPaymentClickEvent$() {
    return this.addPaymentClickEvent.asObservable()
  }

  /**
   * Emit click event.
   */
  addPaymentClicked() {
    this.addPaymentClickEvent.next();
  }

  /**
   * New Job Options local storage save selected values
   * @param NewJobOptions
   */

  public setNewJobOptionsData(newJobOptions: NewJobOptions) {
    this.removeNewJobOptionsData()
    localStorage.setItem('NewJobOptionsData', JSON.stringify(newJobOptions));
  }

  /**
   * Remove NewJobOptionsData from local storage 
   */

  public removeNewJobOptionsData() {
    localStorage.removeItem('NewJobOptionsData');
  }

  /**
  * Remove NewJobOptionsData from local storage 
  */

  public getNewJobOptionsData(): NewJobOptions | null {
    try {
      let strData = localStorage.getItem('NewJobOptionsData') as string;
      let stateData = JSON.parse(strData) as NewJobOptions;
      return stateData;
    } catch (error) {
      return null;
    }

  }

  /**
   * Save Selected Columns to local storage based on component key
   * @param NewJobOptions
   */

  public saveSelectedColumns(selectedColumns: String[], componentKey: string) {
    this.removeSelectedColumnsData(componentKey)
    localStorage.setItem(componentKey, JSON.stringify(selectedColumns));
  }

  /**
   * Remove Selected Columns from local storage 
   */

  public removeSelectedColumnsData(componentKey: string) {
    localStorage.removeItem(componentKey);
  }

  /**
  * Get Selected Columns from local storage 
  */

  public getSelectedColumnsData(componentKey: string): string[] | null {
    try {
      let strData = localStorage.getItem(componentKey) as string;
      let columnsData = JSON.parse(strData) as string[];
      return columnsData;
    } catch (error) {
      return null;
    }

  }

  async getProjectData(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.MANAGE_PROJECTS, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getLanguageData(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.PROJECT_LANGUAGES, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getPresetsData(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.ACCOUNT_PRESETS, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getTatsData(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.PROJECT_TAT_SETTINGS, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getAccountsUsersTeamsData(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.ACCOUNT_USERS_TEAMS, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async createJobData(data: any) {
    try {
      const res = await this.api.post(this.config.JOBS.CREATE_JOB, data, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async uploadJobFile(url: string, data: any) {
    try {
      let headers = new HttpHeaders();
      headers = headers.set('Content-type', 'multipart/form-data');
      const res = await this.http.put(url, data, { headers: headers }).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }
  async getJobStatusByJobIds(data: any) {
    try {
      const res = await this.api.post(this.config.JOBS.JOB_STATUS, data, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async deleteJobById(data: any) {
    try {
      const res = await this.api.delete(this.config.JOBS.MANAGE_JOB, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getFilesList(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.LISTING_FILES, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getAssignmentList(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.LISTING_ASSIGNMENTS, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getAssignmentDetails(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.FILE_ASSIGNMENT, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async updateAssignmentDetails(data: any) {
    try {
      const res = await this.api.put(this.config.JOBS.FILE_ASSIGNMENT, data, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async jobInitiator(data: any) {
    try {
      const res = await this.api.post(this.config.JOBS.JOB_INITIATOR, data, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }
  async openJob(data: any) {
    try {
      const res = await this.api.post(this.config.JOBS.OPEN_JOB, data, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getProgressFileData(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.FILE_PROGRESS, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getWorkItemsOutputByJobID(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.WORK_ITEM_OUTPUTS, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getConsumptionList(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.CONSUMPTION_LIST, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async getProjectWiseConsumption(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.PMA_DASHBOARD, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }
  async getPmaBalance(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.PMA_DASHBOARD, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }
  async getTransactionList(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.ACCOUNT_TRANSACTIONS, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }
  async getPricingList(data: any) {
    try {
      const res = await this.api.get(this.config.ACCOUNT_CREATION.ACCOUNT_PRICES, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }
  async getWorkItemListParts(data: any) {
    try {
      const res = await this.api.get(this.config.JOBS.WORK_ITEM_PARTS, true, data).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  async publishOutputs(data: any) {
    try {
      const res = await this.api.post(this.config.JOBS.PUBLISH_OUTPUTS, data, true).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
}
