import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import {
  AccountProject,
  AccountProjectBasicDetails,
  AccountProjectFeature,
  AccountProjectGetOptions,
} from '../models/accountProject.model';
import { ASR } from '../models/asrSetup.model';
import { OperatingMethodsPayload } from '../models/operatingMethods.model';
import { PerformanceLevel } from '../models/performanceLevel.model';
import { Presets } from '../models/presets.model';
import { ProjectAccess } from '../models/projectAccess.model';
import { ProjectOutput } from '../models/projectOutput.model';
import { ProjectWorkflow } from '../models/projectWorkflow.model';
import { AccountPricessByID } from '../models/workflow.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectCreationStepsService {

  private newlyCreatedRecordPreset: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  newlyCreatedRecordPresetObs$ = this.newlyCreatedRecordPreset.asObservable()
  /**
   * Emits the Newly Created Record
   * @param  {any} value
   */
  nextNewlyCreatedRecord(value: any) {
    this.newlyCreatedRecordPreset.next(value)
  }
  
  creationSteps = [
    {
      name: 'project details',
      link: '/pages/project-creation-steps/project-details'
    },
    {
      name: 'setup workflow',
      link: '/pages/project-creation-steps/setup-workflow'
    },
    {
      name: 'ASR',
      link: '/pages/project-creation-steps/asr-setup'
    },
    {
      name: 'Translation',
      link: '/pages/project-creation-steps/translation-languages'
    },
    {
      name: 'default preset',
      link: '/pages/project-creation-steps/global-customized-preset'
    },
    {
      name: 'output requirements',
      link: '/pages/project-creation-steps/output-requirements'
    },
    {
      name: 'turn around time',
      link: '/pages/project-creation-steps/turn-around-time'
    },
    {
      name: 'heirarchy',
      link: '/pages/project-creation-steps/hierarchy'
    },
    // {
    //   name: 'select permission for heirarchy',
    //   link: '/pages/project-creation-steps/hierarchy'
    // },
    {
      name: 'operating method',
      link: '/pages/project-creation-steps/operating-method'
    },
    {
      name: 'job access to employee',
      link: '/pages/project-creation-steps/employee-job-access'
    },
    {
      name: 'performance levels',
      link: '/pages/project-creation-steps/performance-levels'
    },
    {
      name: 'other settings',
      link: '/pages/project-creation-steps/other-settings'
    },
    {
      name: 'vocabulary files',
      link: '/pages/project-creation-steps/vocabulary'
    },
    {
      name: 'style guide',
      link: '/pages/project-creation-steps/style-guide'
    },
    {
      name: 'guidelines',
      link: '/pages/project-creation-steps/guidelines'
    },
    {
      name: 'email notification setup',
      link: '/pages/project-creation-steps/email-notification'
    },
    {
      name: 'integrations',
      link: '/pages/project-creation-steps/integrations'
    },
    {
      name: 'project access',
      link: '/pages/project-creation-steps/project-access'
    },

  ]
  readonly creationStepsDuplicate: {
    name: string;
    link: string;
}[];
  constructor(private api: ApiService,private http: HttpClient) {
    this.creationStepsDuplicate = this.creationSteps
    const steps_data = localStorage.getItem('steps_data')
    if(steps_data){
      this.creationSteps = JSON.parse(steps_data);
    }
   }

  async getProjectCreationSteps() {
    return this.creationSteps;
  }

  updateSteps(selectedServices: AccountPricessByID[]){
    this.creationSteps = this.creationStepsDuplicate.filter(item => !((item.name === 'ASR' || item.name === 'Translation' ) && !selectedServices.map(x => x.service_name ).includes(item.name)))
    console.log(this.creationSteps);
    
    localStorage.setItem('steps_data', JSON.stringify(this.creationSteps))
  }

  /**
   * This function is to check is user is updating the project or creating the project
   * @returns boolean
   */
  async isCreateProject() {
    const projectType = localStorage.getItem('projectType');
    return projectType === 'create' ? true : false;
  }

  /**
   * API to Create Account Project
   * @param  {AccountProject} payLoad
   * @returns project_id
   */
  async createAccountProject(payLoad: AccountProject) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * API to Update Account Project BasicDetails
   * @param  {AccountProjectBasicDetails} payLoad
   */
  async updateAccountProjectBasicDetails(payLoad: AccountProjectBasicDetails) {
    try {
      payLoad['category'] = 'BASIC_DETAILS';
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * API to Update Account Project Feature
   * @param  {AccountProjectFeature} payLoad
   */
  async updateAccountProjectFeature(payLoad: AccountProjectFeature) {
    try {
      payLoad['category'] = 'FEATURE';
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Account Project Details According to payLoad value
   * @param  {AccountProjectGetOptions} payLoad get responce accourding to the pyaload data
   */
  async getAccountProjectDetails(payLoad: AccountProjectGetOptions) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          true,
          payLoad
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
  * API to get Account Default Languages
  */
  async getProjectDefaultLanguages(supported?: number) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.TRANSLATION_LANGUAGES,
          true,
          (supported && {supported})
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

   /**
  * API to get project Default Languages for translation languages
  */
   async getProjectLanguagesById(project_id: number) {
    try {
      const data = {
        "project_id": project_id,
        "scope": "system_languages"
      }
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.TRANSLATION_LANGUAGES,
          true,
          data
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }


  /**
   * API to insert Project Languages
   */
  async insertProjectLanguages(payLoad: any) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.TRANSLATION_LANGUAGES,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  //step2
  /**
   * Returns Project Workflow list.
   * @default Dont send any params to get default listing.
   * @param  {number} project_id to retrive the ProjectWorkflow record for related project_id
   */
  async getProjectWorkflow(project_id?: number) {
    try {
      const res = await this.api.get(AppConfig.PROJECT_CREATION_STEPS.PROJECT_WORKFLOW, true, project_id ? { project_id } : null)
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * API to create the SetupWorkflow
   * @param  {ProjectWorkflow} payLoad
   */
  async createProjectWorkflow(payLoad: ProjectWorkflow) {
    try {
      const res = await this.api
        .post(AppConfig.PROJECT_CREATION_STEPS.PROJECT_WORKFLOW, payLoad, true)
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * API to update SetupWorkflow
   * @param  {ProjectWorkflow} payLoad
   */
  async updateProjectWorkflow(payLoad: ProjectWorkflow) {
    try {
      const res = await this.api
        .put(AppConfig.PROJECT_CREATION_STEPS.PROJECT_WORKFLOW, payLoad, true)
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * API to insert Project Languages
   */
  async updateProjectLanguages(payLoad: any) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.TRANSLATION_LANGUAGES,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
  * API to fetch enabled Project Languages
  */
  async fetchEnableProjectLanguages(data: any) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.TRANSLATION_LANGUAGES,
          true,
          data
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
   * API to fetch TAT List
   */
  async getTatList(data: any) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_TAT_SETTINGS,
          true,
          data
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
   * API to create/update TAT
   */
  async createTatList(payLoad: any) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_TAT_SETTINGS,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /** Check tat status*/
  async checkStatus(data: any) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          true,
          data
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /** Check tat status*/
  async updateStatus(data: any) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          data,
          true,
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
 * API to Update Service Ids.
 */
  async updatedServcieIds(data: any) {
    try {
      data['category'] = "SERVICE_UPDATE"
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          data,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }


  /**
   * API to get Account Presets.
   * @param  {number} account_id Return Account Presets for `account_id`
   * @param   is_complete send `1` to get complete record. Default value `0`
   * @param  {number} preset_id send to get individual record. Optional
   */
  async getPresets(account_id: number, is_complete: 0 | 1 = 0, preset_id?: number,) {
    try {
      const queryParam: Record<string, number> = {
        account_id, is_complete
      }
      if (preset_id) queryParam['preset_id'] = preset_id
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PRESETS,
          true,
          queryParam
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * API to get Account Presets.
   * @param  {number} user_id Return Account Presets for `user_id`
   * @param   is_complete send `1` to get complete record. Default value `0`
   * @param  {number} preset_id send to get individual record. Optional
   */
   async getPresetsByUserId(is_complete: 0 | 1 = 0, preset_id?: number,) {
    try {
      const queryParam: Record<string, number> = {
        // user_id, 
        is_complete
      }
      if (preset_id) queryParam['preset_id'] = preset_id
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PRESETS,
          true,
          queryParam
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }


  /**
  * API to Create Account Preset.
  */
  async createPreset(payLoad: Presets) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PRESETS,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
* API to Update Account Preset.
*/
  async updatePreset(payLoad: Presets) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PRESETS,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * API to Delete Custom Preset.
   * @param  {number} preset_id
   * @param  {number} account_id
   */
  async deleteCustomPreset(preset_id: number) {
    try {
      const res = await this.api
        .delete(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PRESETS,
          true,
          { preset_id },
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Default Presets for API
   * @param  {number} project_id
   * @param  {number} account_id
   */
  async getDefaultPresets(project_id: number) {
    try {

      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          true,
          {
            feature: 'default_preset_id',
            project_id
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
   * API to get Global Preset.
   * @param  {number} preset_id Optional. Send `preset_id` When want to get individual Global Preset. If `preset_id` is not sent returns all Global Preset.
   * @param  {number} is_complete send 1 to get whole record.
   */
  async getGlobalPresets(preset_id?: number, is_complete: 0 | 1 = 0) {
    try {
      const queryParam: Record<string, number> = { is_complete }
      if (preset_id) queryParam['preset_id'] = preset_id
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.GLOBAL_PRESETS,
          true,
          queryParam
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
  * API to Create Global Preset.
  */
  async createGlobalPreset(payLoad: Presets) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.GLOBAL_PRESETS,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
  * API to Update Global Preset.
  */
  async updateGlobalPreset(payLoad: Presets) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.GLOBAL_PRESETS,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * API to Delete Global Preset.
   * @param  {number} preset_id
   */
  async deleteGlobalPreset(preset_id: number) {
    try {
      const res = await this.api
        .delete(
          AppConfig.PROJECT_CREATION_STEPS.GLOBAL_PRESETS,
          true,
          {
            preset_id,
            // user_id
          },
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
   *  API to Update Operating Method.
   * @param  {OperatingMethodsPayload} payLoad
   */
  async updateProjectOperatingMethod(payLoad: OperatingMethodsPayload) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PRESETS,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Performance Level Status
   * @param  {number} project_id
   * @param  {number} account_id
   */
  async getPerformanceLevelStatus(project_id: number) {
    try {

      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          true,
          {
            feature: 'performance_level_status',
            project_id
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Job Assign Type
   * @param  {number} project_id
   * @param  {number} account_id
   */
  async getJobAssignType(project_id: number) {
    try {

      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          true,
          {
            feature: 'job_assign_type',
            project_id
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Operating Method Status
   * @param  {number} project_id
   * @param  {number} account_id
   */
  async getOperatingAssignType(project_id: number) {
    try {

      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          true,
          {
            feature: 'job_operating_method',
            project_id
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Operating Method Status
   * @param  {number} project_id
   * @param  {number} account_id
   */
   async getSplitJobsStatus(project_id: number) {
    try {

      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          true,
          {
            feature: 'split_jobs',
            project_id
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Default Output Requirement
   */
  async getAllOutputRequirement(project_id: number) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_OUTPUT_SETTINGS,
          true,
          { project_id }
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Output Requirement Related to `project_id`
   * @param  {number} project_id
   */
  async getOutputRequirementByProjectId(project_id: number) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_OUTPUT_SETTINGS,
          true,
          {
            project_id,
            operation: 'list'
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }
  /**
   * Create Output Requirement
   * @param  {ProjectOutput.Post.ProjectOutput} payLoad
   */
  async createOutputRequirement(payLoad: ProjectOutput.Post.ProjectOutput) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_OUTPUT_SETTINGS,
          payLoad,
          true,
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  async updateOutputRequirement(payLoad: ProjectOutput.Put.ProjectOutput) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_OUTPUT_SETTINGS,
          payLoad,
          true,
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
   * Get email notifications
   */
  async getEmailNotifications(data: any) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.EMAIL_NOTIFICATIONS,
          true,
          data
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Create email notifications
   */
  async createEmailNotifications(data: any) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.EMAIL_NOTIFICATIONS,
          data,
          true,
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Upate email notifications
   */
  async updateEmailNotifications(data: any) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.EMAIL_NOTIFICATIONS,
          data,true)
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Performance Level Form API
   * @param  {number} project_id
   */
  async getPerformanceLevel(project_id: number) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PERFORMANCE_LEVEL,
          true,
          {
            project_id
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * Update Performance Level
   * @param  {PerformanceLevel.Post.PerformanceLevel} payLoad
   */
  async updatePerformanceLevel(payLoad: PerformanceLevel.Post.PerformanceLevel) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.PERFORMANCE_LEVEL,
          payLoad,
          true,
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * API to Delete Notifications
   */
  async deleteEmailNotifications(data: any) {
    try {
      const res = await this.api
        .delete(
          AppConfig.PROJECT_CREATION_STEPS.EMAIL_NOTIFICATIONS,
          true,
          data,
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Get metadata Form API
   * @param  data
   */
   async getMetaData(data: any) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_META_DATA,
          true,
          data
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Get metadata Form API
   * @param  data
   */
  async getMetaD(data: any) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_META_D,
          true,
          data
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Get metadata text Form API
   * @param  data
   */
   async getMetaDataText(data: any) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_META_D,
          true,
          data
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
  * API to create metadata
  */
  async createMetaD(payLoad: any) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_META_D,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  async uploadFile(payload: any) {
    try {
      let headers = new HttpHeaders();
      headers = headers.set('Content-type', 'multipart/form-data');
      // upload file and report progress
      const req = new HttpRequest('PUT', payload.URL, payload.File, {
        reportProgress: true,
        headers: headers
      });
      const res = await this.http.request(req).toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error)
    }
  }

  /**
  * API to create metadata
  */
   async createMetaData(data: any, params ?: any) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_META_DATA,
          data,
          true,
          params
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

 /**
  * API to create metadata text
  */
   async createMetaDataText(data: any) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_META_D,
          data,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Upate metadata text
   */
    async updateMetaDataText(data: any) {
      try {
        const res = await this.api
          .put(
            AppConfig.PROJECT_CREATION_STEPS.PROJECT_META_D,
            data,
            true
          )
          .toPromise();
        return res;
      } catch (e: any) {
        return Promise.reject(e?.error);
      }
    }

  /**
   * Get other settings API
   */
   async getOtherSettings(data: any) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_OTHER_SETTINGS,
          true,
          data
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Upate other settings text
   */
   async updateArchivePolicy(data: any) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_OTHER_SETTINGS,
          data,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Create other settings text
   */
   async createArchivePolicy(data: any) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_OTHER_SETTINGS,
          data,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Delete metadata
   */
  async deleteMetaData(data: any) {
    try {
      const res = await this.api
        .delete(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_META_D,
          true,
          data
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * Get all User List
   * @param  {{[key:string]:number}} option
   */
  async getProjectUsers(option:ProjectAccess.GetOptions){
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.MANAGE_USER,
          true,
          {
            ...option
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Enabled Users.
   * @param  {number} project_id
   */
  async getEnabledUsers(project_id:number){
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_USERS,
          true,
          {
            project_id
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Unassigned Users.
   * @param  {number} project_id
   */
   async getUnassignedUsers(project_id:number){
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_USERS,
          true,
          {
            project_id,
            tab:'un-assigned'
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   *
   * @param  {number} account_id
   */
  async getUsersByAccountId(account_id:number){
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_USERS,
          true,
          {
            account_id
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * Get Teams.
   * @param  {ProjectAccess.GetOptions} options
   */
  async getUserTeams(options:ProjectAccess.GetOptions){
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.MANAGE_TEAM,
          true,
          {
            ...options
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * Save Project Users.
   * @param  {ProjectAccess.PUT.Payload} payLoad
   */
  async createProjectUser(payLoad: ProjectAccess.PUT.Payload) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_USERS,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Update Project Users.
   * @param  {ProjectAccess.PUT.Payload} payLoad
   */
  async updateProjectUser(payLoad: ProjectAccess.PUT.Payload) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_USERS,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Get Project Access Status
   * @param  {number} project_id
   * @param  {number} account_id
   */
   async getProjectAccessStatus(project_id:number) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          true,
          {
            // account_id,
            feature:'project_access',
            project_id
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
   * Get project heirarchy
   */
  async getHeirarchy(data: any) {
    try {
      const res = await this.api
      .get(
        AppConfig.PROJECT_CREATION_STEPS.PROJECT_HEIRARCHY,
        true,
        data
      )
      .toPromise();
    return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Update/Create heirarchy
   */
  async updateHeirarchy(data: any) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_HEIRARCHY,
          data,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Get Mode and Content Type Details.
   */
  async getModeContentTypes(){
    try {
      const res = await this.api
      .get(
        AppConfig.PROJECT_CREATION_STEPS.PROJECT_ASR_SETUP,
        true,
        {scope:'mode-content-types'}
      )
      .toPromise();
      return res
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * Get setup Details By `language_id`
   * @param  {number} language_id
   */
  async getAsrDetailsByLanguage(language_id:number){
    try {

      const res = await this.api
      .get(
        AppConfig.PROJECT_CREATION_STEPS.PROJECT_ASR_SETUP,
        true,
        {language_id}
      )
      .toPromise();
      return res
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * Update/Create ASR
   */
   async updateASR(payLoad: ASR.POST.Payload) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_ASR_SETUP,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }
  /**
   * Get ASR details by `project_id`
   * @param  {number} project_id
   */
  async getAsrDetailByProjectId(project_id:number){
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_ASR_SETUP,
          true,
          {project_id},
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  /**
   * GET User roles by project id
   */
   async getUserRolesByProjectId(data: any) {
    try {
      const res = await this.api.get(AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_ROLES, true, data).toPromise();
      return res;
    } catch (e: any) {
      console.log(e);
      return Promise.reject(e?.error)
    }
  }

   /**
   * Call API to update user roles in admin-new-role page
   */
    async udateRoleWithPermissions(data: any) {
      try {
        const res = await this.api.put(AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_ROLES, data, true).toPromise();
        return res;
      } catch (e: any) {
        return Promise.reject(e?.error)
      }
    }
    /**
     * Call API to Duplicate the Project.
     * @param  {number} account_project_id
     */
    async duplicateProject(account_project_id:number){
      try {
        const res = await this.api.put(AppConfig.PROJECT_CREATION_STEPS.DUPLICATE_ACCOUNT_PROJECTS, {}, true,{account_project_id}).toPromise();
        return res;
      } catch (e: any) {
        return Promise.reject(e?.error)
      }
    }
     /**
   * Call API to Delete the Project.
   * @param {number} project_id
   */
     async deleteProject(project_id:number, user_id:number){
      try {
        
        const res = await this.api.delete(
            AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT, 
            true,
            { 
              project_id
            }
          ).toPromise();
        return res;
      } catch (e: any) {
        return Promise.reject(e?.error)
      }
    }

   /**
   * Get download captions Related to `project_id` and page
   * @param  {number} project_id
   */
  async getDownloadCaptionsByProjectId(project_id: number,page:string) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_OUTPUT_SETTINGS,
          true,
          {
            project_id: project_id,
            scope: 'list_output_formats',
            page: page
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  /**
   * Get download captions Related to `project_id` and page
   * @param  {number} project_id
   */
  async getEditProjectSteps(project_id: number) {
    try {
      const res = await this.api
        .get(
          AppConfig.PROJECT_CREATION_STEPS.ACCOUNT_PROJECT,
          true,
          {
            project_id: project_id,
            feature:'project-edit'
          }
        )
        .toPromise();
      return res;
    } catch (e: any) {

      return Promise.reject(e?.error);
    }
  }

  async getProjectIntegrations(project_id?: number) {
    try {
      const res = await this.api.get(AppConfig.PROJECT_CREATION_STEPS.PROJECT_INTEGRATIONS, true, project_id ? { project_id } : null)
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

  async getAuthIntegrations(payload: any) {
    try {
      const res = await this.api.get(AppConfig.PROJECT_CREATION_STEPS.YOUTUBE_AUTH, true, payload)
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

   /**
   * post output formats 
   */
   async OutputFormats(payLoad: any) {
    try {
      const res = await this.api
        .post(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_INTEGRATIONS,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

   /**
   * enable or disable 
   */
   async enableOrDisable(payLoad: any) {
    try {
      const res = await this.api
        .put(
          AppConfig.PROJECT_CREATION_STEPS.PROJECT_INTEGRATIONS,
          payLoad,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

   /**
   * delete account 
   */
  async deleteAccount(project_id: number, service_id: number) {
    try {
      const res = await this.api
        .delete(
          `${AppConfig.PROJECT_CREATION_STEPS.PROJECT_INTEGRATIONS}?project_id=${project_id}&service_id=${service_id}`,
          true
        )
        .toPromise();
      return res;
    } catch (e: any) {
      return Promise.reject(e?.error);
    }
  }

}


