import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectCreationStepsService } from '../../services/project-creation-steps.service';

@Component({
  selector: 'app-auth-redirect',
  templateUrl: './auth-redirect.component.html',
  styleUrls: ['./auth-redirect.component.scss']
})
export class AuthRedirectComponent implements OnInit {

  constructor(private route:ActivatedRoute,public projectCreationStepsService: ProjectCreationStepsService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(async params => {
      let data = {
        status: params['status'],
        code: params['code']
      }
      let res: any = await this.projectCreationStepsService.getAuthIntegrations(data);
      if (res && res.statusCode == 200) {
        const redirectURL = localStorage.getItem('authRedirectURL');
        console.log('redirectURL', redirectURL);
        if (redirectURL) {
          window.location.href = redirectURL;
        }
      }
    });
  }

}
