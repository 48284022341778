<mat-dialog-content>
  <div class="container">
    <span class="header">You will be signed out due to inactivity </span>
    <div class="time-container">
      <span class="time-text">Time remaining:</span> <span class="time-value">{{getFormattedTime(remainingTime) }} minutes</span>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onStayActive()" class="stay-button">Keep me signed in</button>
</mat-dialog-actions>