import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface InactivityAlertData {
  // Pass timer value (seconds) or remaining inactivity time (milliseconds) here
  timer?: number;
  inactivityTime?: number;
}

@Component({
  selector: 'app-inactivity-alert',
  templateUrl: './inactivity-alert.component.html',
  styleUrls: ['./inactivity-alert.component.scss']
})
export class InactivityAlertComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InactivityAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InactivityAlertData
  ) { }

  private timeout: any;
  public remainingTime = this.data.timer || this.data.inactivityTime || 0; // Choose appropriate value

  ngOnInit() {
    // Start the countdown timer if timer value is provided
    if (this.data.timer) {
      this.timeout = setInterval(() => {
        this.remainingTime--;
        if (this.remainingTime <= 0) {
          clearInterval(this.timeout);
          this.dialogRef.close(); // Close dialog on timeout
        }
      }, 1000);
    }
  }

  onStayActive() {
    clearInterval(this.timeout); // Clear timer if user clicks "Stay Active"
    this.dialogRef.close(true); // Close dialog with user confirmation
  }

  getFormattedTime(timerInSeconds:number):string{
    const minutes=Math.floor(timerInSeconds/60);
    const seconds=timerInSeconds%60;
    return `${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`;
  }

}
